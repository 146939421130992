<template>
  <v-layout column align-center @click.stop="">
    <DefaultButton
      class="ma-0 up"
      bgColor="primary"
      small
      icon
      fab
      @mousedown.prevent="() => start('up')"
      @mouseleave.prevent="stop"
      @mouseup.prevent="stop"
      @touchstart.prevent="() => start('up')"
      @touchend.prevent="stop"
      @touchcancel.prevent="stop"
      ><v-icon small>mdi-chevron-up</v-icon>
    </DefaultButton>
    <v-layout>
      <DefaultButton
        class="ma-0 mr-1"
        bgColor="primary"
        small
        icon
        fab
        @mousedown.prevent="() => start('left')"
        @mouseleave.prevent="stop"
        @mouseup.prevent="stop"
        @touchstart.prevent="() => start('left')"
        @touchend.prevent="stop"
        @touchcancel.prevent="stop"
        ><v-icon small>mdi-chevron-left</v-icon>
      </DefaultButton>
      <DefaultButton
        class="ma-0 ml-1"
        bgColor="primary"
        small
        icon
        fab
        @mousedown.prevent="() => start('right')"
        @mouseleave.prevent="stop"
        @mouseup.prevent="stop"
        @touchstart.prevent="() => start('right')"
        @touchend.prevent="stop"
        @touchcancel.prevent="stop"
        ><v-icon small>mdi-chevron-right</v-icon>
      </DefaultButton>
    </v-layout>
    <DefaultButton
      class="ma-0 bottom"
      bgColor="primary"
      small
      icon
      fab
      @mousedown.prevent="() => start('down')"
      @mouseleave.prevent="stop"
      @mouseup.prevent="stop"
      @touchstart.prevent="() => start('down')"
      @touchend.prevent="stop"
      @touchcancel.prevent="stop"
      ><v-icon small>mdi-chevron-down</v-icon>
    </DefaultButton>
  </v-layout>
</template>

<script>
export default {
  timers: {
    repeat: { time: 100, repeat: true },
  },
  data() {
    return {
      direction: null,
    };
  },
  methods: {
    start(direction) {
      this.direction = direction;
      this.$timer.start("repeat");
    },
    stop() {
      this.direction = null;
      this.$timer.stop("repeat");
    },
    repeat() {
      this.$emit(this.direction);
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
};
</script>

<style scoped>
.up {
  margin-bottom: -5px !important;
}
.bottom {
  margin-top: -5px !important;
}
</style>